import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesDKillerWife: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>D: Killer Wife guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_dkiller.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>D: Killer Wife guide & review</h1>
          <h2>
            A guide & review for D: Killer Wife in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>11/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_dkiller.jpg"
          alt="D: Killer Wife"
        />
        <p>
          <strong>D: Killer Wife</strong> banner will be available:{' '}
          <strong>3/7 After Maintenance ~ 3/20 23:59 (UTC+9)</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
        </div>
        <p>
          Normally an executioner and now taking a different role, D: Killer
          Wife, with her newfound glamor, comes to surprise us with a dark tale
          full of moral dilemma and ethically questionable moments. An
          eyewitness to fate's shadowy whims, D: Killer Wife is yet another
          support unit to add to everyone's roster. She offers a unique buff
          that strengthens the team's Pierce Damage, alongside a substantial
          Cooldown Reduction that complements the other minor offensive buffs
          granted via Burst Skill.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Our first impression is that D: Killer Wife is adequately powerful
          thanks to her CDR buff alone. In NIKKE, having at least one CDR in
          team allows you to enjoy 3 (4 if fast enough) more Full Bursts
          attainable in a single 3-minute run. This leads to a considerable
          increase in damage output. D: Killer Wife also grants Pierce Damage,
          which belongs to the same rarely diluted group of Attack Damage and
          Part Damage, that empowers any attacks tagged with Pierce (regardless
          of whether there is any surface to pierce). This makes D: Killer Wife
          a great pair for any Sniper Rifle wielder possessing Pierce. Let's not
          forget that she belongs to Elysion too (finally, ShiftUp)!
        </p>
        <p>
          Unfortunately, she is not flawless. Thanks to her gimmicky Burst
          Skill, she may be a tad situational. Units are required to hit a part
          to gain Core Damage. This means against bosses without parts or
          without core, or against mobs, this component is worthless.
        </p>
        <Alert variant="primary">
          <p>
            Since Alice and Red Hood are excellent units that always have a spot
            in almost every game mode, the value that D's buffs provide rise
            significantly. However, do note that Liter is still superior in most
            cases because she is more universal and boasts greater
            amplifications overall.
          </p>
        </Alert>
        <h6>Skill 1</h6>
        <blockquote>
          <p>
            ■ Activates when attacking with Full Charge for 3 time(s). Affects
            self.
          </p>
          <p>Gain Pierce for 1 round.</p>
          <p>
            ■ Activates when entering Full Burst. Affects all allies with a
            Sniper Rifle.
          </p>
          <p>Pierce Damage ▲ 13.55% for 10 sec.</p>
        </blockquote>
        <ul>
          <li>
            Pierce on self should help with burst generation but is negligible.
          </li>
          <li>
            Pierce Damage is an offensive buff that belongs to the group of
            Attack Damage and Parts Damage.
          </li>
          <li>
            This buff only applies to Nikkes who wield Sniper Rifles by default.
            Ideal for Red Hood and Alice and not applicable on Snow White. Good
            on Maxwell.
          </li>
        </ul>
        <h6>Skill 2</h6>
        <blockquote>
          <p>
            ■ Activates when attacking with Full Charge for 8 time(s). Affects
            all allies.
          </p>
          <p>Cooldown of Burst Skill ▼ 7 sec.</p>
          <p>
            ■ Activates when attacking with Full Charge for 5 time(s). Affects
            all allies.
          </p>
          <p>Attack damage ▲ 5.06% for 10 sec.</p>
        </blockquote>
        <ul>
          <li>
            The most prominent part of her kit thanks to the CDR and perpetual
            Attack Damage buff.
          </li>
          <li>
            CDR scales with level and should activate roughly once per rotation
            with 8 base ammo (obtainable via OL and/or Cube). More in Investment
            Advice.
          </li>
          <li>
            Unlike Liter's, Volume's, or Dolla's, the CDR immediately reaches
            maximum potential at the start of the battle. This is one huge
            advantage.
          </li>
          <li>
            Eight Full Charge on AUTO costs 22 frames + charge time, resulting
            in a base interval of 1.367s between shots. Perfect animation
            interruption on MANUAL can save 11 frames per shot.
          </li>
          <li>
            Normally, it takes around 11 secs to proc the CDR with unlimited
            ammo. Otherwise, add 2.367s for one reload (13.367s aggregated).
          </li>
          <li>
            Attack Damage refreshes every 5 Full Charge(s) and lasts for 10
            seconds. Since the time taken to do 5 Full Charge(s) is always less
            than 10 seconds, even when a reload is necessary, this buff can be
            considered as permanent.
          </li>
        </ul>
        <h6>Burst</h6>
        <blockquote>
          <p> ■ Affects the enemy nearest to the crosshair.</p>
          <p>Deals 269.28% of final ATK as additional damage.</p>
          <p>Inflicts Wipe Out on the target for 10 sec.</p>
          <p>
            ■ Activates when allies' normal attack hits a certain area of the
            target afflicted with Wipe Out. Affects allies.
          </p>
          <p>Buff takes effect depending on the area hit.</p>
          <p>
            Allies that hit parts: Damage dealt when attacking core ▲ 16.26% for
            10 sec.
          </p>
          <p>
            Allies that hit the body: ATK ▲ 12.19% of caster's ATK for 10 sec.
          </p>
        </blockquote>
        <ul>
          <li>
            Inflicts the debuff Wipe Out to the enemy nearest to the crosshair.
            Be careful when using her on AUTO against a wave of enemies.
          </li>
          <li>
            Allies that attack the affected enemy's part gains a 10s Core Damage
            buff, which does NOT refresh and can only be activated again AFTER
            the buff expires.
          </li>
          <li>
            For example, if the buff is received at the 9th second, then the
            buff will extend to the 19th second. And, if you are already in the
            next Full Burst thanks to CDR, you can trigger the buff again after
            the current one ends.
          </li>
          <li>
            Allies that attack the affected enemy's body gains a caster's ATK
            buff that operates similarly.
          </li>
          <li>
            The Core Damage and ATK buffs are independent, which means both can
            be triggered at once or one after the other. Triggering one does not
            prevent the other one from triggering.
          </li>
          <li>
            These buffs and their corresponding timers are individual. Every
            unit is treated independently.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <h6>Campaign</h6>
        <p>
          A viable alternative to Liter, Dorothy, or Volume. CDR is good for
          campaign when you need to rotate faster depending on how the stage
          goes. Buffs can be very limiting as some do not apply universally, but
          she performs exceptionally well in Sniper Rifle teams.
        </p>
        <h6>Elysion Tower</h6>
        <p>
          Might find a spot if CDR is urgently needed. The issue is right now
          Elysion has barely any burst gen outside Vesti. Other battery units
          exhibit poor performance, sadly. Regardless, she is one of the only
          two SSR B1 in Elysion tower with a 20s CD. Therefore, D:Wife can be
          used effectively to progress in Elysion Tower if the setups favor her
          over Miranda (which should be yes in most cases).
        </p>
        <h6>Raids</h6>
        <p>
          She supersedes Volume in Union Raid and is here to stay in Solo Raid
          for a very long time. CDR is too useful to be left out, especially if
          the unit can also enhance meta DPS.
        </p>
        <h6>Simulation Overclock</h6>
        <p>
          No one knows what will be the next restrictions, but having a 20s B1
          CDR Sniper Rifle may help navigate certain weapon restrictions.
        </p>
        <h6>PVP</h6>
        <p>
          D:Wife is not meta for PvP. She is a PvE unit. She may have some use
          in niche setups due to the fact that her CDR is not dependent on Full
          Burst, but overall you should avoid using D:Wife on PvP.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          First of all, D:Wife needs to have at least 8 ammo per rotation to
          avoid double reloads, which adds an unnecessary 2s choke. To make S2
          triggers more uniform and reliable, you may opt to get Max Ammo lines.
          One should be enough, as it only requires a constant number of 8 Full
          Charge(s) per trigger. Still, if you are short on rocks, try to live
          without OL, as those extra few seconds of waiting will probably do no
          harm. In fact, with Wingman Cube level 7, you may not need to OL her
          at all, except if you want to increase her base ATK for buffing
          purposes. In this case, you want to prioritize her Helmet first, then
          Armor/Gloves as usual.
        </p>
        <Alert variant="primary">
          <p>
            Do NOT bother getting Charge Speed lines. This only helps shorten
            intervals a little. They are not useless per se but, considering the
            cost, nope. If you get one randomly, feel free to keep it, but make
            sure to get a minimum of one Max Ammo line.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 4-10</strong>
          </li>
          <ul>
            <li>
              Pierce Damage is free TDM for everyone with SR and Pierce. Since
              this group is rarely diluted, expect a nearly raw improvement.
              There is no breakpoint. You can sleep on this and upgrade beyond 7
              later if desired in the future.
            </li>
            <li className="highlight">
              <strong>
                In the formula, if no other ATK DMG or Parts Damage buffs are
                present, 10% Pierce Damage will amount to a 10% damage output
                increase.
              </strong>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 7-10</strong>
          </li>
          <ul>
            <li>
              Higher CDR is almost always necessary, as most of the time,
              rotations are fast when there is an SR unit in team. You can start
              with lower levels like 4 and try her out. If you feel choked,
              upgrade. Also, this skill gives Attack Damage permanently to
              everyone, so free TDM too and universal at that.
            </li>
          </ul>
          <li>
            <strong>Burst: 4-7</strong>
          </li>
          <ul>
            <li>
              Gimmicky, but you should be able to obtain some sort of value
              against most bosses with core and parts. ATK buff is always
              guaranteed at least. Core Damage is also a nice damage
              amplification. The problem is the values are quite low.
            </li>
            <li className="highlight">
              <strong>
                For reference, a 10% Core Damage increase buff translates to a
                maximum of 5% damage boost when hitting cores. This value
                decreases with Full Burst, Critical, and Damage Distance
                bonuses.
              </strong>
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          With a minimum of one Max Ammo line, Resilience is best in helping
          make S2 triggers more uniform. Bastion can be used as an alternative
          if slots are full or if you have tons of Max Ammo. With no Max Ammo
          lines, however, you can select Wingman (min. level 7). Not 7 yet? Use
          Resilience for now.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A familiar team with D instead of Liter. Here, D buffs Alice
          considerably and still somewhat empowers SBS. Nevertheless, D's buffs
          will be slightly diluted here. If Liter's presence with Bunnies is
          preferred, then this is what works best.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          FLEX can be Alice, Maxwell, or other B3. This is another team
          featuring D. You should probably try some teams out and see which
          combinations offer the most damage.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="d-killer-wife" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="aqua-marine-helm"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="vesti" enablePopover />
              <NikkeCharacter mode="icon" slug="helm" enablePopover />
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="privaty-unkind-maid"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guillotine" enablePopover />
            </div>
          </div>
        </div>
        <p>
          One of the new Elysion Tower flagship teams. D replaces Miranda.
          Marciana or Helm can be used for healing, S.Helm can be used for extra
          CDR to make up for Vesti's short 5-second bursts. Privaty and Vesti
          are general picks for any stage. Choose your final Burst 3 according
          to the situation.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          <strong>
            Yes. One copy is enough, but we believe she is a must have for
            seasoned commanders and beginners alike. TOP META SUPPORT.
          </strong>
        </p>
        <p>
          Perhaps you have a question that sounds like — what about exchanging
          Golden Mileage for her? Unless you really care about Raids (especially
          the Solo version), we discourage such interchange, especially if you
          already own Dorothy, Liter, and/or other CDR units. If she is to be
          your first/second CDR unit, we strongly encourage considering this
          proposition, but keep the next paragraph in mind.
        </p>
        <p>
          Coupons are extremely valuable, and you don't want to miss out on the
          upcoming good DPS, which will probably have a bigger influence on your
          account. Half anniversary is only around the corner! If you decide to
          tread the path of skipping, make sure to wishlist D:Wife.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>A competent Elysion unit among other weaker units.</li>
                  <li>Specialized for meta DPS.</li>
                  <li>CDR is valued everywhere (except PvP).</li>
                  <li>
                    Sniper Rifle means decent burst generation for PvE content.
                  </li>
                  <li>Pierce Damage is a new and competent buff.</li>
                  <li>
                    Offers a lot of rarely diluted buffs (ATK DMG, Pierce DMG,
                    and Core DMG).
                  </li>
                  <li>ATK DMG can be considered permanent.</li>
                  <li>Looks like Yor.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Pierce DMG is niché for Sniper Rifles.</li>
                  <li>
                    Needs some investments on S2 to be fully optimal CDR wise.
                  </li>
                  <li>
                    Caster's ATK buff, so may need OL for extra base stats
                    ideally. (OL lines do not affect her ATK buff)
                  </li>
                  <li>
                    One component of Burst Skill is locked behind hitting parts
                    (and also requires the presence of core).
                  </li>
                  <li>
                    Needs 1 Max Ammo from OL gear at least (or Wingman Lv. 7) to
                    have smooth S2 triggers.
                  </li>
                  <li>Is not Yor.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesDKillerWife;

export const Head: React.FC = () => (
  <Seo
    title="D: Killer Wife guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for D: Killer Wife in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
